import React from "react";

import styles from "./text-section.module.scss";

export default function TextSection() {
  return (
    <section className={`container ${styles.textSection}`}>
      <h1>Our Hygge concept</h1>
      <div>
        <p>Benvenuto a la Cómega.</p>
        <p>
          Se ti stai chiedendo come si pronunci, il modo migliore per farlo è
          quello che ti viene meglio.
          <br />
          La Cómega per noi è un ricordo che ci porta indietro nel tempo. Era il
          modo tutto suo con cui la nostra nonna aveva soprannominato la sua
          inseparabile caffettiera.
        </p>
        <p>
          Abbiamo cercato di capire da dove derivasse questo soprannome
          giungendo alla conclusione che molto probabilmente sia la storpiatura
          del termine dialettale <i>cógoma</i>, in italiano <i>cuccuma</i>, che
          per l’appunto sta a rappresentare un recipiente di rame usato per
          riscaldare il caffè ed altre bevande.
        </p>
        <p>
          La Cómega dettava il ritmo delle giornate di tutta la famiglia. Con il
          suo sbuffare ed il suo inconfondibile aroma che invadeva l’intera
          casa, donava ogni mattino un dolce risveglio e la carica giusta per la
          giornata.
        </p>
        <p>
          Sono questi i motivi per cui la Cómega per noi non è un semplice
          oggetto ma un vero ricordo, un insieme di sentimenti e stati d’animo
          che si traducono in passione e armonia, quella sensazione di calore,
          accoglienza e familiarità che ci contraddistingue..
          <br />
          <br />
          quell’atmosfera che i danesi chiamano <i>Hygge</i>.
        </p>
      </div>
    </section>
  );
}
