import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import styles from "./services.module.scss";

export default function Services() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "IMG_5545.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <section>
      <BackgroundImage className={styles.services} fluid={imageData}>
        <div className={styles.fade}></div>
        <div className="container">
          <h1>I nostri servizi</h1>
          <div className={styles.servicesList}>
            <div className={styles.servicesItem}>
              <h3>Colazione e caffetteria</h3>
            </div>
            <div className={styles.servicesItem}>
              <h3>Pranzo e take-away</h3>
            </div>
            <div className={styles.servicesItem}>
              <h3>Merenda e selezione di tè e tisane</h3>
            </div>
            <div className={styles.servicesItem}>
              <h3>Tartineria e la nostra cantina</h3>
            </div>
            <div className={styles.servicesItem}>
              <h3>Drinchetti d’aperitivo</h3>
            </div>
            <div className={styles.servicesItem}>
              <h3>Picnic Box</h3>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </section>
  );
}
