import React from "react";

import logo from "../images/logo-no-tagline-white.png";

import styles from "./footer.module.scss";

import Consts from "./consts.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  const { facebookLink, instagramLink, mapLink } = Consts();

  return (
    <section className={styles.footer}>
      <div className={styles.footerTagline}>
        <div className="container">
          <h2>Il sapore delle buone abitudini</h2>
        </div>
      </div>

      <div className={`container ${styles.whereWeAre}`}>
        <h1>Dove siamo</h1>

        <div>
          <p>
            <a
              href="https://goo.gl/maps/N2KYqv4LKva7WWyo7"
              target="_blank"
              rel="noreferrer"
            >
              Piazza Campo Marzio 18/G
              <br />
              36071, Arzignano, VI
            </a>
          </p>
          <p>
            <a href="tel:+393534227238">+39 353 4227238</a>
          </p>
          <p>
            <a href="mailto:info@lacomega.it">info@lacomega.it</a>
          </p>
        </div>
      </div>

      <div className={styles.iconsAndSocialsWrapper}>
        <div className={`container ${styles.iconsAndSocials}`}>
          <div className={styles.section}>
            <p>
              © {year} La Cómega S.R.L.
              <br />
              All Rights Reserved
            </p>
          </div>

          <div className={styles.section}>
            <img src={logo} alt="La Cómega logo" width="auto" height="100px" />
          </div>

          <div className={styles.section}>
            <div className={styles.icons}>
              <a
                href={facebookLink}
                target="_blank"
                rel="noreferrer"
                alt="La Cómega Facebook"
              >
                <FontAwesomeIcon
                  className={styles.fb}
                  icon={faFacebookSquare}
                />
              </a>
              <a
                href={instagramLink}
                target="_blank"
                rel="noreferrer"
                alt="La Cómega Instagram"
              >
                <FontAwesomeIcon className={styles.insta} icon={faInstagram} />
              </a>
              <a
                href={mapLink}
                target="_blank"
                rel="noreferrer"
                alt="La Cómega direzioni"
              >
                <FontAwesomeIcon className={styles.fb} icon={faMapMarkerAlt} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
