import React from "react";

import MenuSection from "./menu-section.js";

import styles from "./menu.module.scss";

function normalise_items(menuSectionItems) {
  const normalised_items = menuSectionItems.map((item) => {
    return {
      text: item.titolo.text,
      price: item.prezzo_1,
      subText: item.descrizione.text,
      subPrice: item.prezzo_2,
    };
  });
  return normalised_items;
}

function normalise_notes(rawNotes) {
  return rawNotes.map((rawNote) => {
    return rawNote.nota.html;
  });
}

function normalise_section(title, rawItems, rawNotes) {
  return {
    header: title,
    items: normalise_items(rawItems),
    notes: normalise_notes(rawNotes),
  };
}

const Menu = ({ menu }) => {
  const section1Items = normalise_section(
    menu.data.sezione_1_titolo.text,
    menu.data.sezione_1,
    []
  );
  const section2Items = normalise_section(
    menu.data.sezione_2_titolo.text,
    menu.data.sezione_2,
    []
  );
  const section3Items = normalise_section(
    menu.data.sezione_3_titolo.text,
    menu.data.sezione_3,
    []
  );
  const section4Items = normalise_section(
    menu.data.sezione_4_titolo.text,
    menu.data.sezione_4,
    menu.data.note
  );
  const section5Items = normalise_section(
    menu.data.sezione_5_titolo.text,
    menu.data.sezione_5,
    []
  );
  const section6Items = normalise_section(
    menu.data.sezione_6_titolo.text,
    menu.data.sezione_6,
    []
  );
  const section7Items = normalise_section(
    menu.data.sezione_7_titolo.text,
    menu.data.sezione_7,
    []
  );
  const section8Items = normalise_section(
    menu.data.sezione_8_titolo.text,
    menu.data.sezione_8,
    []
  );
  const section9Items = normalise_section(
    menu.data.sezione_9_titolo.text,
    menu.data.sezione_9,
    []
  );
  const section10Items = normalise_section(
    menu.data.sezione_10_titolo.text,
    menu.data.sezione_10,
    []
  );
  const section11Items = normalise_section(
    menu.data.sezione_11_titolo.text,
    menu.data.sezione_11,
    []
  );
  const section12Items = normalise_section(
    menu.data.sezione_12_titolo.text,
    menu.data.sezione_12,
    []
  );
  const section13Items = normalise_section(
    menu.data.sezione_13_titolo.text,
    menu.data.sezione_13,
    []
  );

  return (
    <section id="menu" className={styles.menu}>
      <div className="container">
        <h1>Le nostre proposte</h1>
        <MenuSection section={section1Items} />
        <MenuSection section={section2Items} />
        <MenuSection section={section3Items} />
        <MenuSection section={section4Items} />
        <MenuSection section={section5Items} />
        <MenuSection section={section6Items} />
        <MenuSection section={section7Items} />
        <MenuSection section={section8Items} />
        <MenuSection section={section9Items} />
        <MenuSection section={section10Items} />
        <MenuSection section={section11Items} />
        <MenuSection section={section12Items} />
        <MenuSection section={section13Items} />
      </div>
    </section>
  );
};

export default Menu;
