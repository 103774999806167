import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import logo from "../images/logo-tagline-white.png";

import styles from "./banner.module.scss";

import Consts from "./consts.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export default function Banner({ openingTimes }) {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "file2.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const { facebookLink, instagramLink } = Consts();

  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <section className={styles.banner}>
      <BackgroundImage
        className={styles.bannerImage}
        fluid={imageData}
      ></BackgroundImage>

      <div className={styles.logoAndSocialIcons}>
        <img src={logo} alt="La Cómega" width="350px" height="auto" />
        <div className={styles.icons}>
          <a
            href={facebookLink}
            target="_blank"
            rel="noreferrer"
            alt="La Cómega Facebook"
          >
            <FontAwesomeIcon size="3x" icon={faFacebookSquare} />
          </a>
          <a
            href={instagramLink}
            target="_blank"
            rel="noreferrer"
            alt="La Cómega Instagram"
          >
            <FontAwesomeIcon size="3x" icon={faInstagram} />
          </a>
        </div>
        <div
          className={styles.openingTimes}
          dangerouslySetInnerHTML={{ __html: openingTimes.html }}
        ></div>
      </div>
    </section>
  );
}
