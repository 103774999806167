import React, { Component } from "react";

import styles from "./menu-section.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

class MenuSection extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.toggleSection = this.toggleSection.bind(this);
  }

  toggleSection() {
    this.setState((state) => ({ open: !state.open }));
  }

  sectionClasses(open) {
    if (open) {
      return styles.menuSection + " " + styles.menuSectionOpen;
    } else {
      return styles.menuSection;
    }
  }

  renderMenuItems(items) {
    return items.map((item, index) => (
      <div key={index} className={styles.menuItemWrapper}>
        <div className={styles.menuItem}>
          <span>{item.text && <strong>{item.text}</strong>}</span>
          {item.price && <span>{item.price}</span>}
        </div>
        <div className={styles.subItem}>
          {item.subText && <span>{item.subText}</span>}
          {item.subPrice && <span>{item.subPrice}</span>}
        </div>
      </div>
    ));
  }

  renderNotes(items) {
    if (items.notes) {
      return items.notes.map((note, index) => (
        <div
          key={index}
          className={styles.notesSection}
          dangerouslySetInnerHTML={{ __html: note }}
        ></div>
      ));
    }
  }

  render() {
    const items = this.props.section.items;
    const menuSectionClass = this.sectionClasses(this.state.open);
    const section = <div>{this.renderMenuItems(items)}</div>;

    return (
      <div>
        <div className={menuSectionClass}>
          <button
            className={styles.menuSectionHeader}
            onClick={this.toggleSection}
          >
            <h2>{this.props.section.header}</h2>
            <FontAwesomeIcon size="3x" icon={faAngleDown} />
          </button>
          <div className={styles.menuSectionBody}>
            {section}
            {this.renderNotes(this.props.section)}
          </div>
        </div>
      </div>
    );
  }
}

export default MenuSection;
