import * as React from "react";

import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Banner from "../components/banner.js";
import TextSection from "../components/text-section.js";
import Menu from "../components/menu.js";
import Services from "../components/services.js";
import Footer from "../components/footer.js";
import CookieConsent from "react-cookie-consent";

const IndexPage = ({ data: { prismicMenuV2 } }) => {
  return (
    <>
      <Helmet>
        {/* TODO: https://github.com/kentcdodds/kentcdodds.com/blob/main/src/components/seo/index.js */}
        <title>La Cómega</title>
        {/* <meta name="description" content={description} /> */}

        <meta property="og:url" content="https://lacomega.it" />
        <meta property="og:title" content="La Còmega" />
        {/* <meta property="og:descrizione" content="La Còmega" /> */}
      </Helmet>
      <main>
        <Banner openingTimes={prismicMenuV2.data.orari_d_apertura} />
        <TextSection />
        <Menu menu={prismicMenuV2} />
        <Services />
        <Footer />
        <CookieConsent
          buttonText="Accetta"
          style={{
            background: "#382e2c",
            borderTop: "1px solid white",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          buttonStyle={{
            color: "#383e2c",
            background: "white",
            fontSize: "16px",
          }}
          cookieName="gatsby-gdpr-google-analytics"
        >
          Questo sito web utilizza i cookie.
          <br />
          Utilizziamo i cookie per personalizzare contenuti ed annunci, per
          fornire funzionalità dei social media e per analizzare il nostro
          traffico.
          <br />
          Acconsenti ai nostri cookie se continui ad utilizzare il nostro sito
          web.
        </CookieConsent>
      </main>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query MenuQuery {
    prismicMenuV2 {
      data {
        orari_d_apertura {
          html
        }
        sezione_1_titolo {
          text
        }
        sezione_1 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_2_titolo {
          text
        }
        sezione_2 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_3_titolo {
          text
        }
        sezione_3 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_4_titolo {
          text
        }
        sezione_4 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }
        note {
          nota {
            html
          }
        }

        sezione_5_titolo {
          text
        }
        sezione_5 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_6_titolo {
          text
        }
        sezione_6 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_7_titolo {
          text
        }
        sezione_7 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_8_titolo {
          text
        }
        sezione_8 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_9_titolo {
          text
        }
        sezione_9 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_10_titolo {
          text
        }
        sezione_10 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_11_titolo {
          text
        }
        sezione_11 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_12_titolo {
          text
        }
        sezione_12 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }

        sezione_13_titolo {
          text
        }
        sezione_13 {
          titolo {
            text
          }
          descrizione {
            text
          }
          prezzo_2
          prezzo_1
        }
      }
    }
  }
`;
